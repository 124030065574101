<template>
  <div class="row">
    <div class="row">
      <Notif :notif="notif" />
    </div>
    <div class="col-md-12 mx-auto mt-3">
      <div class="row">
        <div class="col-sm-3">
          <a class="btn btn-blue"
             data-toggle="modal"
             ref="modal_button"
             data-target="#exampleModal"
             @click="effacerForm(),modification=false">
            Créer une charge
          </a>
        </div>
      </div>
      <div class="row pt-3 mx-auto">
        <table class="table table-striped">
          <thead>
            <tr>
              <th class="th-blue"> 
                Libellé
              </th>
              <!-- <th class="th-blue text-right">
                Coût 
              </th> -->
              <th class="th-blue">
                Periode
              </th>
              <!-- <th class="th-blue text-right">
                Nombre de période
              </th> -->
              <th class="th-blue">
                Date de début
              </th>
              <!-- <th class="th-blue">
                Date de fin
              </th> -->
              <!-- <th class="th-blue">
                Groupe
              </th> -->
              <th class="th-blue text-right">
                Actions
              </th>
            </tr>
          </thead>
          <tbody v-if="!loaded">
            <tr scope="row">
              <td colspan="7"
                  class="text-center">Chargement en cours... </td>
            </tr>
          </tbody>
          <tbody v-else-if="loaded && long ==0">
            <tr scope="row">
              <td colspan="7"
                  class="text-center">Aucune donnée enregistrée...</td>
            </tr>
          </tbody>
          <tbody v-else>
            <template v-for="(groupe, grokey) in listGroupe">
              <tr :key="grokey">
                <td colspan="7"
                    class="tht-blue text-center text-uppercase"
                    @click="showContent(groupe)">{{ groupe == "autres"?"autres charges":"charges d'"+groupe }}</td>
              </tr>
              <template v-for="(charge,chakey) in charges">
                <tr scope="row"
                    :key="grokey+'_'+chakey"
                    v-if="groupe==charge.groupe">
                  <template v-if="contenu[groupe]">   
                    <td >{{ charge.libelle }}</td>
                    <!-- <td class="text-right">{{ charge.cout }}</td> -->
                    <td >{{ charge.periodes }}</td>
                    <!-- <td class="text-right">{{ charge.nombre_periode }}</td> -->
                    <td >{{ charge.date_debut }}</td>
                    <!-- <td >{{ charge.date_fin }}</td> -->
                    <!-- <td >{{ charge.groupe }}</td> -->
                    <td class="text-right">
                      <button class="btn btn-blue mr-1"
                              @click="launchDetailsCharges(ids.projet,ids.produit,charge.id)">+</button>
                      <button type="button"
                              data-toggle="modal" 
                              data-target="#exampleModal" 
                              class="btn btn-blue mr-1"
                              @click="chargerCharge(ids.projet,ids.produit,charge.id)">
                        <i class="flaticon-pencil"></i>
                      </button>
                      <!-- <button type="button"
                                   data-toggle="modal" 
                                   data-target="#exampleModal" 
                                   class="btn btn-blue">
                              <i class="flaticon-delete"></i>
                      </button> -->
                    </td>
                  </template>
                </tr>
              </template>
              
            </template>
            
          </tbody>
        </table>
      </div>
      <!-- Modal -->
      <div class="modal fade"
           id="exampleModal"
           tabindex="-1"
           role="dialog"
           aria-labelledby="exampleModalLabel"
           aria-hidden="true">
        <div class="modal-dialog"
             role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title"
                  id="exampleModalLabel">Créer une charge</h5>
              <button type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      ref="btn-close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form>
                <div class="form-row">
                  <div class="form-group col-md-12">
                    <label for=""
                    >Groupe </label>
                    <select class="form-control" 
                            v-model="form.groupe">
                      <option value="">Choisissez un groupe</option>
                      <option value="exploitation">Exploitation</option>
                      <option value="investissement">Investissement</option>
                      <option value="autres">Autres</option>
                    </select>
                    
                  </div>
                </div>
                <div class="form-row">
                  
                  <div class="form-group col-md-6">
                    <label for=""
                    >Libellé <sup><span class="req-star">*</span></sup></label>
                    <input type="text"
                           class="form-control"
                           v-model="form.libelle">
                  </div>
                  <div class="form-group col-md-6">
                    <label for=""
                    >Date de debut</label>
                    <input type="date"
                           class="form-control"
                           v-model="form.date_debut">
                  </div>
                  <!-- <div class="form-group col-md-6">
                    <label for=""
                    >Coût <sup><span class="req-star">*</span></sup></label>
                    <input type="text"
                           class="form-control"
                           v-model="form.cout">
                  </div> -->
                </div>
                <div class="form-row">
                  
                  <!-- <div class="form-group col-md-6">
                    <label for=""
                    >Date de fin</label>
                    <input type="date"
                           class="form-control"
                           v-model="form.date_fin">
                  </div> -->
                </div>
                
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label for=""
                    >Periode </label>
                    <select class="form-control" 
                            v-model="form.periodes"
                            @change="initEcheance()">
                      <option value="">Choisissez une période</option>
                      <option value="mois">Mensuelle</option>
                      <option value="annees">Annuelle</option>
                    </select>
                    
                  </div>
                  <div class="form-group col-md-6">
                    <label for=""
                    >Nombre de périodes <sup><span class="req-star">*</span></sup></label>
                    <input type="text"
                           class="form-control"
                           v-model="form.nombre_periode"
                           @change="initEcheance()">
                  </div>
                </div>
                <template v-if="form.echeances.length>0">
                  <div class="form-row"
                       v-for="(echeance, echkey) in formulaire.echeances"
                       :key="echkey">
                    <div class="form-group col-md-4">
                      <label for=""
                      >Date (échéance {{ echkey==0?"":"+"+echkey }})</label>
                      <input type="date"
                             class="form-control"
                             v-model="echeance.date"
                             @change="initEcheance()"
                             disabled>
                    </div>
                    <div class="form-group col-md-4">
                      <label for=""
                      >Quantité (échéance {{ echkey==0?"":"+"+echkey }})</label>
                      <input type="text"
                             class="form-control"
                             v-model="form.echeances[echkey].quantite">
                    </div>
                    <div class="form-group col-md-4">
                      <label for=""
                      >Montant(échéance {{ echkey==0?"":"+"+echkey }}) </label>
                      <input type="text"
                             class="form-control"
                             @input="addSpace(echkey)"
                             v-model="echeance.montant">
                    </div>
                  </div>
                </template>
              </form>
            </div>
            <div class="modal-footer">
              <button type="button"
                      class="btn btn-secondary"
                      data-dismiss="modal"
                      @click="effacerForm()">Annuler</button>
              <button type="button"
                      class="btn btn-primary"
                      v-if="!modification"
                      @click="submit">créer</button>
              <button type="button"
                      class="btn btn-primary"
                      v-else
                      @click="modifierCharge()">Modifier</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import form from "vuejs-form"
import Notif from "@/components/shared/Toast"

export default {
  name: "Hypotheses",
  components: {
    Notif
  },
  data: () => ({
    notif: {
      activated: "",
      type: "",
      message: ""
    },
    modification:false,
    listGroupe:["exploitation","investissement","autres"],
    charges:[],
    loaded:false,
    long:0,
    formulaire:{
      echeances:[],
    },
    form: form({
      id:'',
      libelle :'',
      cout :'',
      periodes :'',
      nombre_periode :'',
      echeances:[],
      date_debut :'',
      date_fin :'',
      groupe :'',
      projet:'',
      produit:''
    }).rules({
      'libelle' : 'required', 
    }),
    old_periode:"",
    contenu:{
      exploitation:true,
      investissement:true,
      autres:true
    },
  }),
  created() {
    this.contenu={
      exploitation:true,
      investissement:true,
      autres:true
    }
    this.getListCharges({projet:this.ids.projet, produit:this.ids.produit})
    this.form.projet = this.ids.projet
    this.form.produit = this.ids.produit
  },
  watch: {
    listCharges(){
      if (this.listCharges) {
        this.charges=this.listCharges.donnees
        // this.charges.forEach(charge => {
        //   console.log(charge)
        //   charge.date_debut=charge.date_debut.split("T")[0]
        //   charge.date_fin=charge.date_fin.split("T")[0]
        // })
        this.long= this.charges.length
        this.loaded=true
      }
    },
    addCharge(){
      if (this.addCharge) {
        this.notif.message = "La charge a été créée."
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.$refs["btn-close"].click()
            this.effacerForm()
            this.loaded=false
            this.getListCharges({projet:this.ids.projet,produit:this.ids.produit})
          }.bind(this),
          2000
        )
      }
    },
    failAddCharge(){
      if (this.failAddCharge) {
        this.notif.message = "Une erreur s'est produite."
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
          }.bind(this),
          2000
        )
      }
    },
    charge(){
      if (this.charge) {
        this.form.id = this.charge.id
        this.form.libelle  = this.charge.libelle
        this.form.cout  = this.charge.cout
        this.form.periodes  = this.charge.periodes
        this.form.nombre_periode  = this.charge.echeances.length-1
        this.form.date_debut  = this.charge.echeances[0].date
        this.form.date_fin  = this.charge.date_fin
        this.form.groupe  = this.charge.groupe
        this.form.echeances= this.charge.echeances
        this.charge.echeances.forEach(echeance => {
          this.formulaire.echeances.push({
            date:echeance.date,
            montant:""+echeance.montant+"",
            quantite:""
          })

        })
      }
    },
    failCharge(){
      if (this.failCharge) {
        this.notif.message = "Une erreur s'est produite."
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
          }.bind(this),
          2000
        )
      }
    },
    msgSuccessCharge(){
      if (this.msgSuccessCharge) {
        this.notif.message = "La charge a été modifiée."
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.$refs["btn-close"].click()
            this.effacerForm()
            this.loaded=false
            this.getListCharges({projet:this.ids.projet,produit:this.ids.produit})
          }.bind(this),
          2000
        )
      }
    },
    msgFailCharge(){
      if (this.msgFailCharge) {
        this.notif.message = "Une erreur s'est produite."
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
          }.bind(this),
          2000
        )
      }
    },
  },
  computed: {
    ...mapGetters(["listCharges","addCharge","charge","msgSuccessCharge","failListCharges","failAddCharge","failCharge","msgFailCharge",])
  },
  methods: {
    ...mapActions(["getListCharges","postCharge","putCharge","getCharge","putCharge"]),
    ...mapMutations(["setListCharges","setAddCharge","setCharge","setmsgSuccessCharge","setFailListCharges","setFailAddCharge","setFailCharge","setMsgFailCharge",]),
    launchDetailsCharges(projet,produit,id) {
      this.$router.push({ name: "DetailCharges", params: { projet: projet, produit: produit, charge:id } })
    },
    effacerForm(){
      this.form.libelle =''
      this.form.cout =''
      this.form.periodes =''
      this.form.nombre_periode =''
      this.form.date_debut =''
      this.form.date_fin =''
      this.form.echeances =[]
      this.formulaire.echeances = []
      this.form.groupe =''     
    },
    submit(){
      this.form.projet = this.ids.projet
      this.form.produit = this.ids.produit
      this.postCharge(this.form.data)
    },
    chargerCharge(projet,produit,id){
      this.modification=true
      this.getCharge({projet:projet,produit:produit,charge:id})
    },
    modifierCharge(){
      // this.form.projet=this.$route.params.id
      this.putCharge(this.form.data)
    },
    addSpace(key) {
      var chaine = ""
      var splinter = this.formulaire.echeances[key].montant.split("")
      var res =""
      this.form.echeances[key].montant=""
      if (/[0-9]/.test(this.formulaire.echeances[key].montant) || !/[A-Za-zéèàôêâîûöïüëä$&+,:;=?@#|"~{}'<>.-^*()%!]/.test(this.formulaire.echeances[key].montant)) {
        for (let index = 0; index < splinter.length; index++) {
          if (!/[0-9]/.test(splinter[index]) && !splinter[index] == " ") {
            splinter[index] = ""
          }
          chaine += splinter[index]
        }
        if (this.formulaire.echeances[key].montant.length >= 4) {
          // check if there's a space
          if (this.formulaire.echeances[key].montant.includes(" ")) {
            var trimer = chaine.split(" ")
            chaine = ""
            for (let index = 0; index < trimer.length; index++) {
              chaine += trimer[index]
            }
            chaine = chaine.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          } else {
            chaine = ""
            chaine = this.formulaire.echeances[key].montant.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          }
        }
      }
      this.formulaire.echeances[key].montant = chaine
      res = this.formulaire.echeances[key].montant.split(" ")
      for (let index = 0; index < res.length; index++) {
        this.form.echeances[key].montant += res[index]
      }
      this.form.echeances[key].montant=Number(this.form.echeances[key].montant)
    },
    initEcheanceOld(){
      var difference =0
      if (this.old_periode!=this.form.periodes) {
        this.old_periode=this.form.periodes
        this.form.echeances=[]
        this.formulaire.echeances=[]
        difference = this.form.nombre_periode
      }else{
        difference =Number(this.form.nombre_periode) - Number(this.form.echeances.length)
      }
      if(this.form.nombre_periode > this.form.echeances.length){
        for (let index = 0; index < difference; index++) {
          if (this.form.periodes == "mois") {
            var mois = Number(this.form.date_debut.split("-")[1])
            
            this.form.echeances.push({
              date:this.form.date_debut.split("-")[0]+"-"+(mois<10?"0"+(mois+index):mois+index)+"-"+this.form.date_debut.split("-")[2],
              montant:0,
              quantite:""
            })
            this.formulaire.echeances.push({
              date:this.form.date_debut.split("-")[0]+"-"+(mois<10?"0"+(mois+index):mois+index)+"-"+this.form.date_debut.split("-")[2],
              montant:0,
              quantite:""
            })
          }else{
            var annee = Number(this.form.date_debut.split("-")[0])
            this.form.echeances.push({
              date:(annee+index)+"-"+this.form.date_debut.split("-")[1]+"-"+this.form.date_debut.split("-")[2],
              montant:0,
              quantite:""
            })
            this.formulaire.echeances.push({
              date:(annee+index)+"-"+this.form.date_debut.split("-")[1]+"-"+this.form.date_debut.split("-")[2],
              montant:0,
              quantite:""
            })
          }
        }
      }else{
        difference = difference*(-1)
        for (let index = 0; index < difference; index++) {
          this.form.echeances.pop()
          this.formulaire.echeances.pop()
        }
      }
      console.log(this.form.echeances)
    },
    initEcheance(){
      // var difference =0
      // if (this.old_periode!=this.form.periodes) {
      this.old_periode=this.form.periodes
      this.form.echeances=[]
      this.formulaire.echeances=[]
      // difference = this.form.nombre_periode
      // }//else{
      //   difference =Number(this.form.nombre_periode) - Number(this.form.echeances.length)
      // }
      var mois= Number(this.form.date_debut.split("-")[1]),
          nb_annee=parseInt((mois+Number(this.form.nombre_periode))/12),
          annee= Number(this.form.date_debut.split("-")[0]),
          nb_mois_restant=(mois+Number(this.form.nombre_periode))%12
      if (this.form.periodes == "mois") {
        if (nb_annee>0) {
          for (let index = 0; index <= nb_annee; index++) {
            if (index==0 ) {
              for (let jindex = mois; jindex <= 12; jindex++) {
                this.form.echeances.push({
                  date:(annee+index)+"-"+(jindex<10?"0"+jindex:jindex)+"-"+this.form.date_debut.split("-")[2],
                  montant:0,
                  quantite:""
                })
                this.formulaire.echeances.push({
                  date:(annee+index)+"-"+(jindex<10?"0"+jindex:jindex)+"-"+this.form.date_debut.split("-")[2],
                  montant:0,
                  quantite:""
                })
              }
            }else{
              if (index == nb_annee) {
                for (let jindex = 1; jindex <= nb_mois_restant; jindex++) {
                  this.form.echeances.push({
                    date:(annee+index)+"-"+(jindex<10?"0"+jindex:jindex)+"-"+this.form.date_debut.split("-")[2],
                    montant:0,
                    quantite:""
                  })
                  this.formulaire.echeances.push({
                    date:(annee+index)+"-"+(jindex<10?"0"+jindex:jindex)+"-"+this.form.date_debut.split("-")[2],
                    montant:0,
                    quantite:""
                  })
                }
              }else{
                for (let jindex = 1; jindex <= 12; jindex++) {
                  this.form.echeances.push({
                    date:(annee+index)+"-"+(jindex<10?"0"+jindex:jindex)+"-"+this.form.date_debut.split("-")[2],
                    montant:0,
                    quantite:""
                  })
                  this.formulaire.echeances.push({
                    date:(annee+index)+"-"+(jindex<10?"0"+jindex:jindex)+"-"+this.form.date_debut.split("-")[2],
                    montant:0,
                    quantite:""
                  })
                }
              }
            }
            
          }
        }
      }
      
    },
    showContent(key){
      if (this.contenu[key]) {
        this.contenu[key]=false
      }else{
        this.contenu[key]=true
      }
    }
  },
  props: ["ids"]
}
</script>

<style scoped>
  .table tbody td,
  .table tfoot td,
  .table thead th,
  .form input.form-control,
  .form button.btn-info{
    font-size: 0.54em;
  }
  .table tbody tr{
    cursor: pointer;
  }

</style>
