<template>
  <div class="main_container">
    <div class="row mb-3 bread-crumbs">
      <div class="text-left col-12">
        <router-link :to="'/analyses/board'">
          Analyses >
        </router-link>
        <router-link :to="'/analyses/addBplan'">
          Business Plan >
        </router-link>
        <router-link :to="'/analyses/business-plan/'+$route.params.projet+'/Produits'">
          Produits >
        </router-link>
        <!-- <router-link :to="'/analyses/bplan'">
          
        </router-link> -->
        <span>Charges et ressources</span>
      </div>
    </div>
    <div>
      <div class="row justify-content-center my-4">
        <div class="col-8">
          <div class="card title-card">
            <div class="card-body text-center title">
              Business plan
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <ul class="nav nav-tabs mt-3"
        id="myTab"
        role="tablist">
      <li class="nav-item"
          role="presentation">
        <a class="nav-link active"
           id="home-tab"
           data-toggle="tab"
           href="#home"
           role="tab"
           aria-controls="home"
           aria-selected="true"
           @click="selection('charges')">Charges</a>
      </li>
      <li class="nav-item"
          role="presentation">
        <a class="nav-link"
           id="profile-tab"
           data-toggle="tab"
           href="#profile"
           role="tab"
           aria-controls="profile"
           aria-selected="false"
           @click="selection('ressources')">Ressources</a>
      </li>
    </ul>
    <div class="tab-content"
         id="myTabContent">
      <div class="tab-pane fade show active"
           id="home"
           role="tabpanel"
           aria-labelledby="home-tab">
        <Hypotheses :ids="ids"
                    v-if="choix=='charges'"/>
      </div>
      <div class="tab-pane fade"
           id="profile"
           role="tabpanel"
           aria-labelledby="profile-tab">
        <Ressources :ids="ids"
                    v-if="choix=='ressources'"/>
      </div>
    </div>

  </div>
</template>
<style>
@import "./businessPlan.css";
.table-hypothese,
.table-investment,
.table-investment-year,
.table-blue,
.result-value {
  font-size: 0.54em;
}
li.nav-item, 
div.col-md-3.mx-auto h4,
div.ent-title,
div.ent-vt-title,
div.ent-value,
div.ent-vt-value,
.tht-blue,
.result-title,
.bg-secondary{
  font-size: 0.7em;
}

</style>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex"

import Hypotheses from "@/components/Analyses/businessPlan/Hypotheses"
import Ressources from "@/components/Analyses/businessPlan/Ressources"
// import form from "vuejs-form"
// import Notif from "@/components/shared/Toast"
export default {
  name: "ChargesRessources",
  components: {
    Ressources,
    Hypotheses,
    
  },
  data: () => ({
    ids: {
      projet: '',
      produit: ''
    },
    choix:"charges",
    notif: {
      activated: "",
      type: "",
      message: ""
    },
    
  }),
  created() {
    this.ids.produit = this.$route.params.produit 
    this.ids.projet = this.$route.params.projet 
  },
  watch: {
    
  },
  computed: {
    ...mapGetters([])
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    selection(cle){
      this.choix=""
      this.choix=cle
    }
  }
}
</script>
